import type { PropsWithChildren } from 'react';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';

/**
 * Props for the wrapper FC passed as the `wrapper`.
 */
export interface WrapperProps {
  /**
   * Class name(s) to apply to this component.
   */
  className?: string;
  /**
   * Style tag to apply to this component.
   */
  style?: React.CSSProperties;
}

/**
 * Props for Conditional Wrapper.
 */
interface Props extends WrapperProps {
  /**
   * Set a custom wrapper for this component.
   */
  wrapper?: React.FC<React.PropsWithChildren<WrapperProps>>;
  /**
   * The condition in which to render the wrapping div or element specified via the "as" prop.
   */
  condition: boolean;
}

/**
 * Renders a wrapping <div> or custom element when the provided condition evaluates to true
 * Performance Warning: usage of this Component will force remounting on both `wrapper` and `children` on every rerender --
 * avoid this if either contain components with expensive hooks.
 * Sometimes this component will not make much difference, other times it is huge.
 * For those cases, prefer ternary operators and small 'children'.
 * Optimizing this has been attempted with little success:
 * useState - component now only renders once
 * React.memo + react-fast-compare#isEqual - slower than even remounting
 *
 * @author Jonathan Bridges, Vaibhav Chawla
 * @deprecated given the performance concerns it is recommended against using this component.
 */
const ConditionalWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  wrapper: Wrapper,
  condition,
  className,
  children,
  style
}: PropsWithChildren<Props>) => {
  const cx = useClassNameMapper();

  if (!condition) {
    return <>{children}</>;
  }

  const Component = Wrapper ?? 'div';

  return (
    <Component className={cx(className)} style={style}>
      {children}
    </Component>
  );
};

export default ConditionalWrapper;
